import React from 'react'
import { Link, useLocation } from 'react-router-dom'

// Assets
import homeActiveIcon from 'assets/navbar/main/icons/homeActive.svg'
import homeInactiveIcon from 'assets/navbar/main/icons/homeInactive.svg'
import chatActiveIcon from 'assets/navbar/main/icons/chatActive.svg'
import chatInactiveIcon from 'assets/navbar/main/icons/chatInactive.svg'
import connectionActiveIcon from 'assets/navbar/main/icons/connectionActive.svg'
import connectionInactiveIcon from 'assets/navbar/main/icons/connectionInactive.svg'
import discoverActiveIcon from 'assets/navbar/main/icons/discoverActive.svg'
import discoverInactiveIcon from 'assets/navbar/main/icons/discoverInactive.svg'
import { MAIN_NAV } from 'shared/router'


function Index() {

    const location = useLocation()


    const navbarMenu = [
        { title: 'Home', img: [homeActiveIcon, homeInactiveIcon], action: MAIN_NAV.HOME },
        { title: 'Discover', img: [discoverActiveIcon, discoverInactiveIcon], action: MAIN_NAV.DISCOVER },
        { title: 'Connection', img: [connectionActiveIcon, connectionInactiveIcon], action: MAIN_NAV.CONNECTION },
        { title: 'Chat', img: [chatActiveIcon, chatInactiveIcon], action: MAIN_NAV.CHAT },
    ]


    return (
        <div className="fixed bottom-0 left-0 z-50 w-full bg-white lg:hidden">
            <div className="flex justify-around">
                {navbarMenu.map((item, index) => {
                    const isActive = location.pathname.startsWith(item.action);
                    return (
                        <Link to={item?.action} key={index} className='md:w-[50px] lg:w-[74px] h-[64px] flex justify-center items-center cursor-pointer'>
                            <div className='flex flex-col items-center justify-center'>
                                <img src={isActive ? item?.img[0] : item?.img[1]} alt="" />
                                <p className={`font-normal text-[12px] ${isActive ? 'text-[#F83758]' : 'text-[#5D6577]'}`}>{item?.title}</p>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>

    )
}

export default Index