
import React from 'react';
import { MainFooter, MainFooterResponsive } from 'components/Footer';
import { MainNavbar, MainNavbarResponsive, BottomNavbarResponsive } from "components/NavbarAndTabs";
import { WalletSideBar } from 'components/sidebars';
import { avoidOptions } from 'utils/constant/routeHandler';


function Index({ children, avoid = [] }) {
    // Default avoid to an empty array if it's not provided

    const isAvoiding = (option) => avoid?.includes(option); // Utility to check if a component should be avoided

    return (
        <div className="flex flex-col min-h-screen">
            {/* Conditionally render Navbar */}
            {!isAvoiding(avoidOptions.NAVBAR) && (
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <MainNavbar />
                    </div>
                    <div className="block lg:hidden">
                        <MainNavbarResponsive />
                    </div>
                </div>
            )}

            {/* Conditionally render BottomNavbarResponsive */}
            {!isAvoiding(avoidOptions.BOTTOM_NAVBAR_RESPONSIVE) && (
                <BottomNavbarResponsive />
            )}

            {/* Page Content */}
            <div className="flex flex-grow w-full h-full min-h-screen bg-white">
                <div className="hidden w-2/12 lg:block">
                    <WalletSideBar />
                </div>
                <div className='w-[98%] md:w-[80%] mx-auto'>
                    {children}
                </div>
            </div>

            {/* Conditionally render Footer */}
            {!isAvoiding(avoidOptions.FOOTER) && (
                <>
                    <div className="hidden w-full bg-black lg:block">
                        <MainFooter />
                    </div>
                    <div className="block mb-10 bg-black lg:hidden">
                        <MainFooterResponsive />
                    </div>
                </>
            )}
        </div>
    );
}

export default Index;
