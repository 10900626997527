import React, { useState, useEffect, useCallback, Component } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfileDetails } from '../../redux/slice/userProfile';
import { AuthenticatedLayout, SettingsLayout, UnAuthenticatedLayout, WalletLayout } from 'components/layout';
import ServerError from 'pages/error/serverError';
import { status } from '../../apis/auth';
import { LAYOUT_TYPES } from 'utils/constant/routeHandler';

// Error boundary to catch rendering errors
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error(`Error caught in route: ${this?.props?.routeName}`);
        console.error('Error details:', error);
        console.error('Component stack info:', info);
    }

    render() {
        if (this.state.hasError) {
            return <ServerError />;
        }
        return this.props.children;
    }
}


const RoutAuthentication = ({ children, auth = 'auth', avoid = [], layoutType = 'authenticated' }) => {
    const dispatch = useDispatch();
    const [authStatus, setAuthStatus] = useState(null);
    const userInfo = useSelector((state) => state.userProfile);

    const authenticate = useCallback(async () => {
        // Check if authentication is required
        if (auth === 'auth') {
            let result = await status();
            result = result?.data
            if (result) {
                setAuthStatus(true);
                if (!userInfo.name) {
                    result = result?.data
                    const updateData = {
                        ...result,
                        personal: {
                            ...result?.personal,
                            religion_id: result?.personal?.religion_id?._id,
                            caste_id: result?.personal?.caste_id?._id,
                        },
                    };
                    dispatch(setUserProfileDetails(updateData));
                }
            } else {
                setAuthStatus(false);
            }
        } else if (auth === 'unAuth') {
            const token = localStorage.getItem('session');
            setAuthStatus(!!token);
        } else {
            setAuthStatus(null); // Skip auth checks for 'none'
        }
    }, [auth, dispatch, userInfo.name]);

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    // Handle loading state
    if (auth !== 'none' && authStatus === null) {
        return <div>Checking authentication...</div>;
    }

    // Handle unauthenticated access for protected routes
    if (auth === 'auth' && !authStatus) {
        localStorage.removeItem('session');
        return <Navigate to="/welcome" replace />;
    }

    // Handle authenticated access for unauthenticated routes
    if (auth === 'unAuth' && authStatus) {
        return <Navigate to="/home" replace />;
    }

    const LayoutToRender = layoutType === LAYOUT_TYPES.AUTHENTICATED ? AuthenticatedLayout
        : layoutType === LAYOUT_TYPES.UNAUTHENTICATED ? UnAuthenticatedLayout
            : layoutType === LAYOUT_TYPES.WALLET ? WalletLayout
                : layoutType === LAYOUT_TYPES.SETTINGS ? SettingsLayout
                    : null

    // Render the component
    return (
        <ErrorBoundary>
            {layoutType === LAYOUT_TYPES.NONE ? (
                <>{children}</>
            ) : (
                LayoutToRender && <LayoutToRender avoid={avoid}>{children}</LayoutToRender>
            )}
        </ErrorBoundary>
    );


};

export default RoutAuthentication;
