import React, { lazy } from "react";

// PREV
import AllPopups from 'components/popUps/allPopups'
import AllEmptyCards from 'components/Cards/emptyCards/allEmptyCards'

// license
import TermsAndConditions from "pages/license/TermsAndConditions";
import PrivacyPolicy from "pages/license/PrivacyPolicy";

import { AUTH, BLOG, BOOKING_PAYMENT, CONNECTIONS, CUPID, ERROR, EVENTS, POPUPS, GPS, LICENSE, MAIN_NAV, MEETINGS, OFFERS, ON_BOARDING_PAGES, PROFILE_EDIT, SETTINGS, SITE_INTO, WALLET, EMPTY_CARDS } from "../shared/router";

// On Boarding
import OnBoardingFirstPage from 'pages/onBoarding/firstPage'
import OnBoardingSecondPage from 'pages/onBoarding/secondPage'
import OnBoardingThirdPage from 'pages/onBoarding/thirdPage'
import OnBoardingFourthPage from 'pages/onBoarding/fourthPage'
import OnBoardingFifthPage from 'pages/onBoarding/fifthPage'
import OnBoardingSixthPage from 'pages/onBoarding/sixthPage'
import OnBoardingSeventhPage from 'pages/onBoarding/seventhPage'


// Profile Edit start
import BasicInformationProfileEditPage from 'pages/ProfileEdit/BasicInformation'
import PhysicalAttributesEditPage from 'pages/ProfileEdit/PhysicalAttributes'
import DietAttributesEditPage from 'pages/ProfileEdit/DietAttributes'
import GroomsLocationEditPage from 'pages/ProfileEdit/groomsLocation'
import FamilyDetailsEditPage from 'pages/ProfileEdit/FamilyDetails'
import PhotoAndGalleryEditPage from 'pages/ProfileEdit/PhotoAndGallery'
import LanguagesEditPage from 'pages/ProfileEdit/Languages'
import InterestEditPage from 'pages/ProfileEdit/Interest'
import EducationDetailsEditPage from 'pages/ProfileEdit/EducationDetails'
import ConnectedAccountsEditPage from 'pages/ProfileEdit/ConnectedAccounts'
import { authenticationProps, avoidOptions, LAYOUT_TYPES } from "utils/constant/routeHandler";
// Profile Edit End


// SITE INTRO
const Landing = lazy(() => import("pages/siteIntro").then(module => ({ default: module.Landing })))
const AboutUs = lazy(() => import("pages/siteIntro").then(module => ({ default: module.AboutUs })))
const EventHome = lazy(() => import("pages/siteIntro").then(module => ({ default: module.Events })))
const HostWithUs = lazy(() => import("pages/siteIntro").then(module => ({ default: module.HostWithUs })))
const Datrimony = lazy(() => import("pages/siteIntro").then(module => ({ default: module.Datrimony })))
const Blog = lazy(() => import("pages/siteIntro").then(module => ({ default: module.Blogs })))
const BlogDetail = lazy(() => import("pages/siteIntro").then(module => ({ default: module.BlogDetail })))

//  Auth
const LoginPage = lazy(() => import('pages/auth').then(module => ({ default: module.Login })))
const SignUpPage = lazy(() => import('pages/auth').then(module => ({ default: module.Signup })))
const WelcomePage = lazy(() => import('pages/auth').then(module => ({ default: module.Welcome })))


// Event 
const DetailPage = lazy(() => import('pages/Event').then(module => ({ default: module.EventDetailedPage })))
const EventFilterPage = lazy(() => import('pages/Event').then(module => ({ default: module.EventFilterPage })))
const Booking = lazy(() => import('pages/Event/').then(module => ({ default: module.EventBooking })))


// Settings Start
const Settings = lazy(() => import('pages/settings').then(module => ({ default: module.Settings })))
const ProfilePreview = lazy(() => import('pages/settings').then(module => ({ default: module.UserProfilePreview })))
const PartnerPreference = lazy(() => import('pages/settings').then(module => ({ default: module.PartnerPreference })))
const WalletSettings = lazy(() => import('pages/settings').then(module => ({ default: module.Wallet })))
const PrivacySettings = lazy(() => import('pages/settings').then(module => ({ default: module.PrivacySettings })))
const AccountSettings = lazy(() => import('pages/settings').then(module => ({ default: module.AccountSettings })))
const Logout = lazy(() => import('pages/settings').then(module => ({ default: module.Logout })))
// Settings End



// Connection start
const FriendsConnectionPage = lazy(() => import("pages/connections").then(module => ({ default: module.Friends })))
const SuggestedConnectionPage = lazy(() => import("pages/connections").then(module => ({ default: module.ConnectionSuggestion })))
const EventsConnectionPage = lazy(() => import("pages/connections").then(module => ({ default: module.EventConnection })))
const RequestedConnectionPage = lazy(() => import("pages/connections").then(module => ({ default: module.ConnectionRequestSendAndReceive })))
const FriendsProfilePreviewPage = lazy(() => import("pages/connections").then(module => ({ default: module.FriendsProfilePreview })))
const RequestedProfilePrev = lazy(() => import("pages/connections").then(module => ({ default: module.ConnectionRequestedUserProfilePrev })))
const QrConnectionProfilePrev = lazy(() => import("pages/connections").then(module => ({ default: module.ProfilePrevForQrConnection })))
// Connection End

// Wallet Section
const WalletPageNew = lazy(() => import("pages/WalletSection").then(module => ({ default: module.Wallet })))
const WalletRechargePage = lazy(() => import("pages/WalletSection").then(module => ({ default: module.WalletRecharge })))
const PlanesPage = lazy(() => import("pages/WalletSection").then(module => ({ default: module.Plans })))
const WalletCard = lazy(() => import("pages/WalletSection").then(module => ({ default: module.WalletCard })))
const WalletHistoryPage = lazy(() => import("pages/WalletSection").then(module => ({ default: module.History })))
const Scan = lazy(() => import("pages/WalletSection").then(module => ({ default: module.QrCodeScanner })))

// Offers
const OffersPage = lazy(() => import("pages/offers").then(moduel => ({ default: moduel.OffersList })))
const OffersDetailsPage = lazy(() => import("pages/offers").then(moduel => ({ default: moduel.OfferDetails })))

// Chat
const ChatPage = lazy(() => import("pages/chat/ChatPage"))
// Meetings
const MeetingPage = lazy(() => import("pages/meetings/MeetingPage"))

// GPS
const GPSPage = lazy(() => import("pages/gps").then(module => ({ default: module.Gps })))
const GPSHistoryPage = lazy(() => import("pages/gps").then(module => ({ default: module.GpsHistory })))


// Cupid
const Cupid = lazy(() => import("pages/cupid"))

// Error
const ServerError = lazy(() => import("pages/error").then(moduel => ({ default: moduel.ServerError })))

const HomePage = lazy(() => import('pages/Home'))
const Discover = lazy(() => import('pages/Discover'))




export const Links = [

    // ========== NEW VERSION START  ========== //
    //  SITE INTRO START
    {
        name: "Landing Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.LANDING,
        element: <Landing />,
        showInNavigation: true,
    },
    {
        name: "About Us Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.ABOUT,
        element: <AboutUs />,
        showInNavigation: true,
    },
    {
        name: "Event Home Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.EVENT_HOME,
        element: <EventHome />,
        showInNavigation: true,
    },
    {
        name: "Host with us Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.HOST_WITH_US,
        element: <HostWithUs />,
        showInNavigation: true,
    },
    {
        name: "Datrmony Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.DATRIMONY,
        element: <Datrimony />,
        showInNavigation: true,
    },
    {
        name: "Blog Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: SITE_INTO.BLOG,
        element: <Blog />,
        showInNavigation: true,
    },
    {
        name: "Blog Detail Page",
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.UNAUTHENTICATED,
        path: BLOG.BLOG_DETAILS,
        element: <BlogDetail />,
        showInNavigation: true,
    },
    //  SITE INTRO END

    // AUTH START
    {
        name: "Signup",
        path: AUTH.SIGNUP,
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        element: <SignUpPage />,
        showInNavigation: true,
    },
    {
        name: "Login",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        path: AUTH.LOGIN,
        element: <LoginPage />,
        showInNavigation: true,
    },
    {
        name: "Welcome Page",
        layoutType: LAYOUT_TYPES.NONE,
        auth: authenticationProps.UNAUTH,
        path: AUTH.WELCOME,
        element: <WelcomePage />,
        showInNavigation: true,
    },
    // AUTH END

    // ON BOARDING STARTS
    {
        name: "On Boarding Page One",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.FIRST,
        element: <OnBoardingFirstPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Two",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.SECOND,
        element: <OnBoardingSecondPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Three",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.THIRD,
        element: <OnBoardingThirdPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fourth",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.FOURTH,
        element: <OnBoardingFourthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Fifth",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.FIFTH,
        element: <OnBoardingFifthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Sixth",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.SIXTH,
        element: <OnBoardingSixthPage />,
        showInNavigation: true,
    },
    {
        name: "On Boarding Page Seventh",
        layoutType: LAYOUT_TYPES.NONE,
        path: ON_BOARDING_PAGES.SEVENTH,
        element: <OnBoardingSeventhPage />,
        showInNavigation: true,
    },
    // ON BOARDING END

    // CONNECTIONS STARTS
    {
        name: "Discover",
        path: MAIN_NAV.DISCOVER,
        avoid: [avoidOptions.FOOTER],
        element: <Discover />,
        showInNavigation: true,
    },
    {
        name: "Cupid Page",
        path: CUPID,
        element: <Cupid />,
        showInNavigation: true,
    },
    {
        name: "Connection Page Friends",
        path: CONNECTIONS.FRIENDS,
        element: <FriendsConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Suggestion Page",
        path: CONNECTIONS.SUGGESTIONS,
        element: <SuggestedConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Events Connection Page",
        path: CONNECTIONS.EVENTS,
        element: <EventsConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Request Page",
        path: CONNECTIONS.REQUESTS,
        element: <RequestedConnectionPage />,
        showInNavigation: true,
    },
    {
        name: "Friends Prev Page",
        path: CONNECTIONS.FRIEND_PREVIEW,
        element: <FriendsProfilePreviewPage />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for connection request send and received user",
        path: CONNECTIONS.REQUESTED_CONNECTION_PROFILE_PREVIEW,
        element: <RequestedProfilePrev />,
        showInNavigation: true,
    },
    {
        name: "User Prev Page for qr Connection",
        path: CONNECTIONS.CONNECTION_FRIEND_PREVIEW,
        element: <QrConnectionProfilePrev />,
        showInNavigation: true,
    },
    // CONNECTIONS END

    // Home Page
    {
        name: "HomPage",
        path: MAIN_NAV.HOME,
        auth: authenticationProps.NONE,
        element: <HomePage />,
        showInNavigation: true,
    },
    // EVENT PAGES START
    {
        name: "Event Detail Page authorized",
        path: EVENTS.EVENT_DETAILS,
        auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.NONE,
        element: <DetailPage />,
        showInNavigation: true,
    },
    {
        name: "Event Booking page",
        path: BOOKING_PAYMENT,
        element: <Booking />,
        showInNavigation: true,
    },
    {
        name: "Event Filter Page",
        // auth: authenticationProps.NONE,
        layoutType: LAYOUT_TYPES.NONE,
        path: EVENTS.EVENT_FILTER,
        element: <EventFilterPage />,
        showInNavigation: true,
    },
    //    EVENT PAGES END

    // Settings Start
    {
        name: "Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.SETTINGS,
        element: <Settings />,
        showInNavigation: true,
    },
    {
        name: "Profile Prev",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PROFILE_PREVIEW,
        element: <ProfilePreview />,
        showInNavigation: true,
    },
    // Profile Edit start
    {
        name: "Profile Edit Basic Information",
        path: PROFILE_EDIT.BASIC,
        element: <BasicInformationProfileEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Physical Attributes",
        path: PROFILE_EDIT.PHYSICAL,
        element: <PhysicalAttributesEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Dies Attributes",
        path: PROFILE_EDIT.DIET,
        element: <DietAttributesEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile edit Location Details",
        path: PROFILE_EDIT.LOCATION,
        element: <GroomsLocationEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Family Details",
        path: PROFILE_EDIT.FAMILY_DETAILS,
        element: <FamilyDetailsEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Photos",
        path: PROFILE_EDIT.PHOTO,
        element: <PhotoAndGalleryEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Languages",
        path: PROFILE_EDIT.LANGUAGES,
        element: <LanguagesEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Interest",
        path: PROFILE_EDIT.INTERESTS,
        element: <InterestEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Education Details",
        path: PROFILE_EDIT.EDUCATION,
        element: <EducationDetailsEditPage />,
        showInNavigation: true,
    },
    {
        name: "Profile Edit Connected Accounts",
        path: PROFILE_EDIT.CONNECTED_ACCOUNTS,
        element: <ConnectedAccountsEditPage />,
        showInNavigation: true,
    },
    // Profile Edit End

    {
        name: "Partner Preference",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PARTNER_PREFERENCE,
        element: <PartnerPreference />,
        showInNavigation: true,
    },
    {
        name: "Privacy Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.PRIVACY_SETTINGS,
        element: <PrivacySettings />,
        showInNavigation: true,
    },
    {
        name: "Account Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.ACCOUNT_SETTINGS,
        element: <AccountSettings />,
        showInNavigation: true,
    },
    {
        name: "Wallet Settings",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.WALLET,
        element: <WalletSettings />,
        showInNavigation: true,
    },
    {
        name: "lOGOUT Page",
        layoutType: LAYOUT_TYPES.SETTINGS,
        path: SETTINGS.LOGOUT,
        element: <Logout />,
        showInNavigation: true,
    },
    // Settings End

    // Wallet
    {
        name: "Wallet Page",
        path: WALLET.WALLET,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletPageNew />,
        showInNavigation: true,
    },
    {
        name: "Wallet Card Page",
        path: WALLET.CARD,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletCard />,
        showInNavigation: true,
    },

    {
        name: "Wallet Recharge Page",
        path: WALLET.RECHARGE,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletRechargePage />,
        showInNavigation: true,
    },
    {
        name: "planes Page",
        path: WALLET.PLANES,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <PlanesPage />,
        showInNavigation: true,
    },
    {
        name: "wallet history Page",
        path: WALLET.HISTORY,
        layoutType: LAYOUT_TYPES.WALLET,
        element: <WalletHistoryPage />,
        showInNavigation: true,
    },
    {
        name: "Scan couple squad QR",
        path: WALLET.SCAN,
        element: <Scan />,
        showInNavigation: true,
    },


    // Offers
    {
        name: "OFFERS Page",
        path: OFFERS.OFFERS,
        element: <OffersPage />,
        showInNavigation: true,
    },
    {
        name: "OFFER DETAILS Page",
        path: OFFERS.OFFER_DETAILS,
        element: <OffersDetailsPage />,
        showInNavigation: true,
    },

    // Chat
    {
        name: "Chat Page",
        path: MAIN_NAV.CHAT,
        element: <ChatPage />,
        showInNavigation: true,
    },
    // Meetings
    {
        name: "Meetings Page",
        path: MEETINGS,
        element: <MeetingPage />,
        showInNavigation: true,
    },

    // LICENSE START
    {
        name: "Terms And Conditions Page",
        path: LICENSE.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
        showInNavigation: true,
    },
    {
        name: "Privacy Policy Page",
        path: LICENSE.PRIVACY_POLICIES,
        element: <PrivacyPolicy />,
        showInNavigation: true,
    },
    // GPS
    {
        name: "GPS Page",
        path: GPS.GPS,
        element: <GPSPage />,
        showInNavigation: true,
    },
    {
        name: "GPS History",
        path: GPS.HISTORY,
        element: <GPSHistoryPage />,
        showInNavigation: true,
    },
    // LICENSE END
    // ERROR PAGE START
    {
        name: "Server Error Page",
        path: ERROR.SERVER_ERROR,
        element: <ServerError />,
        showInNavigation: true,
    },
    {
        name: "Popups",
        path: POPUPS,
        element: <AllPopups />,
        showInNavigation: true,
    },
    {
        name: "Error Cards",
        path: EMPTY_CARDS,
        element: <AllEmptyCards />,
        showInNavigation: true,
    },
    // ERROR PAGES END

    // ========== NEW VERSION END ==========
];
