import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CONNECTIONS } from '../../../shared/router';

function Index({ count = false }) {
    const tabs = [
        { label: "Friends", location: CONNECTIONS.FRIENDS },
        { label: "Suggested", location: CONNECTIONS.SUGGESTIONS },
        { label: "Events", location: CONNECTIONS.EVENTS },
        { label: "Requested", location: CONNECTIONS.REQUESTS },
    ]
    const [selectedTab, setSelectedTab] = useState('Friends')
    const location = useLocation();

    useEffect(() => {
        const currentSidebarItem = tabs.find(item => item.location === location.pathname);
        if (currentSidebarItem) {
            setSelectedTab(currentSidebarItem.label);
        } else {
            setSelectedTab(undefined);
        }
    }, [location]);

    return (
        <div>
            <div className='flex flex-wrap my-5 gap-1 md:gap-5'>
                {tabs.map((item, index) => (

                    <div key={index} className={`w-fit  p-1 px-3 text-base md:text-xl  ${selectedTab === item.label ? 'text-red-500 border-red-500 font-semibold border-b-2' : 'text-black'}`}>
                        <Link className='cursor-pointer' to={item.location} onClick={(e) => {
                            !item.location && e.preventDefault()
                            setSelectedTab(item.label)
                        }}>
                            {selectedTab === item.label ? `${item.label} ${count ? `(${count})` : ''}` : item.label}
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(Index)