import React, { Suspense, useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { Links } from "./Links";
import NotFoundPage from "pages/error/404PageNotFound"
import RoutAuthentication from "components/routeHandler";

export default function Routers() {
  const location = useLocation();
  const ref = useRef(null);
  useEffect(() => {
    ref.current.complete();
  }, [location]);
  return (
    <>
      <LoadingBar ref={ref} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {Links.map((route, i) => {
            return (
              <Route key={i} exact
                // element={route.element}
                element={
                  <RoutAuthentication
                    children={route.element}
                    routeName={route.name}
                    avoid={route?.avoid}
                    auth={route?.auth}
                    layoutType={route?.layoutType}
                  />}

                path={route.path} />
            );
          })}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  )
}
