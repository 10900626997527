import { configureStore } from '@reduxjs/toolkit';
import userProfileReducer from './slice/userProfile';
import userSelectedLocationReducer from './slice/selectedLocation';

export const store = configureStore({
    reducer: {
        userProfile: userProfileReducer,
        userSelectedLocation: userSelectedLocationReducer,
    },
});
