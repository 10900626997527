import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useDataForProfileEdit = () => {
    const userInfo = useSelector((state) => state.userProfile);
    return useMemo(() => {
        const { photo, profile_completion, email, phone, personal, id, verification, ...other } = userInfo;
        const { allow_handpicked, age, zodiac, ...restPersonal } = personal;
        const filteredData = {
            personal: restPersonal,
            ...other,
        };

        const updateData = {
            ...filteredData,
            personal: {
                ...filteredData.personal,
                religion_id: filteredData.personal.religion_id?._id,
                caste_id: filteredData.personal.caste_id?._id,
            },
        };
        return updateData;
    }, [userInfo]);
};
