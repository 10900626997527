export const getAge = date => {
    const birthDate = new Date(date);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function formatToDateMMdd(dateString) {   //'2024-12-07T10:53:16.701Z';
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    return `${month}/${year}`  // 12/24
}


export function formatDate(timestamp) {   // 2024-04-18T09:18:42.951Z
    const date = new Date(timestamp);
    const day = date.getDate()
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day} ${month}`;  // 18 Apr
}

export function formatDateForCarousel({ start_date, end_date }){
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const startMonth = startDate.toLocaleString('en-US', { month: 'short' });
    const endMonth = endDate.toLocaleString('en-US', { month: 'short' });
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    return startDate?.toLocaleDateString() === endDate?.toLocaleDateString() ? `${startDay} ${startMonth} ${startYear}` : `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
}

export function convert24To12Hour(time24) {   //17:20
    const [hours, minutes] = time24.split(':')
    let hoursNum = parseInt(hours, 10)
    const ampm = hoursNum >= 12 ? 'PM' : 'AM'
    hoursNum = hoursNum % 12 || 12
    const formattedHours = hoursNum.toString().padStart(2, '0')
    return `${formattedHours}:${minutes} ${ampm}` //05:20 PM
}

export function convert12To24Hour(time) {
    const [timePart, period] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);
    if (period.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12;
    } else if (period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function calculateTotalHours(startDate, startTime, endDate, endTime) {
    const startTime24 = convert12To24Hour(startTime);
    const endTime24 = convert12To24Hour(endTime);
    const startDateTimeString = `${startDate.slice(0, 10)}T${startTime24}:00.000Z`;
    const endDateTimeString = `${endDate.slice(0, 10)}T${endTime24}:00.000Z`;
    const startDateTime = new Date(startDateTimeString);
    const endDateTime = new Date(endDateTimeString);
    if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
        throw new Error('Invalid date or time format');
    }
    const diffMilliseconds = endDateTime.getTime() - startDateTime.getTime();
    const diffHours = diffMilliseconds / (1000 * 60 * 60);
    return Math.floor(diffHours);
}

export function formatToDayDateTime(dateString, timeString) {
    const date = new Date(dateString);
    const { hours, minutes } = (() => {
        const time24 = convert12To24Hour(timeString);
        const [hours, minutes] = time24.split(':').map(Number);
        return { hours, minutes };
    })();
    date.setUTCHours(hours, minutes, 0, 0);
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC'
    };
    return date.toLocaleString('en-US', options);
}

export function isBookingAllowed(startDate, startTime) {
    const startTime24 = convert12To24Hour(startTime);
    const eventDateTimeString = `${startDate.split('T')[0]}T${startTime24}:00.000Z`;
    const eventDateTime = new Date(eventDateTimeString);
    const now = new Date();
    const oneHourInMilliseconds = 60 * 60 * 1000;
    return eventDateTime.getTime() - now.getTime() >= oneHourInMilliseconds;
}


export function calculateTotalDays(startDate, endDate) {    // 2024-05-24T00:00:00.000Z, 2024-05-31T00:00:00.000Z
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
        throw new Error('Invalid date format');
    }
    const diffMilliseconds = endDateTime.getTime() - startDateTime.getTime();
    const diffDays = diffMilliseconds / (1000 * 60 * 60 * 24);
    return diffDays // 2
}


export function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return 'Invalid date';
    }
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
}

export function convertToDDMMYYYYHrMin(isoDate) {
    const datePart = isoDate.split("T")[0];
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    const timePart = isoDate.split("T")[1].replace("Z", "");
    const [hours, minutes] = timePart.split(":");
    const formattedTime = `${hours}:${minutes}`;
    const amPm = hours >= 12 ? "pm" : "am";
    const formattedTimeWithAmPm = `${formattedTime} ${amPm}`;
    return `${formattedDate} ${formattedTimeWithAmPm}`;
}


export const determineBookingText = (data, bookings) => {
    const now = new Date();
    const prebookingFrom = new Date(data?.prebooking_from);
    const prebookingTo = new Date(data?.prebooking_to);

    if (data?.prebooking_enabled && !bookings?.prebooked) {
        if (now < prebookingFrom) {
            return `Pre-booking starts on ${formatDate(data?.prebooking_from)}`;
        } else if (now >= prebookingFrom && now <= prebookingTo) {
            return `Pre-booking ends on ${formatDate(data?.prebooking_to)}`;
        } else {
            if (data?.sold_out) {
                return 'Sold Out';
            } else if (bookings?.isBooked) {
                return 'Already Booked';
            } else {
                return isBookingAllowed(data?.start_date, data?.start_time) ? "Book Now" : 'Sold Out';
            }
        }
    } else {
        return bookings?.prebooked ? 'Already Pre Booked'
            : bookings?.isBooked ? 'Already Booked'
                : data?.sold_out ? "Sold Out"
                    : isBookingAllowed(data?.start_date, data?.start_time) ? "Book Now" : 'Sold Out';
    }
};


export function timeAgo(inputDate) {
    const now = new Date();
    const past = new Date(inputDate);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
    if (diffInSeconds < 60) {
        return 'just now';
    }
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    }
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        const minutes = diffInMinutes % 60;
        return minutes === 0
            ? `${diffInHours}h ago`
            : `${diffInHours}h ${minutes}min ago`;
    }
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        return diffInDays === 1 ? '1 day ago' : `${diffInDays} days ago`;
    }
    const weeks = Math.floor(diffInDays / 7);
    if (weeks < 4) {
        return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    }
}
