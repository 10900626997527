
import bg_img from "../../../assets/onBoarding/bgImages/pageThreeBgImage.svg"
import back_btn_icon from "../../../assets/onBoarding/back-btn-icon.svg"


import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup'
import CustomDropdown from "../../../components/OnBoarding/DropDown"
import { relationShipValidation } from "../../../utils/validations/onBoarding"
import { couplesMartialStatusOPtions, singlesMartialStatusOPtions } from "utils/constant/user/profileInfo";


function Index() {

    const navigate = useNavigate()
    const [data, setData] = useState({
        relationshipStatus: '',
        martialStatus: ""
    })

    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);

        if (userData?.onBoardingThree) {
            navigate('/on-boarding-4')
        } else if ((userData?.data?.relationshipStatus) && (userData?.data?.martialStatus)) {
            setData({
                relationshipStatus: userData.data.relationshipStatus,
                martialStatus: userData.data.martialStatus
            })
        }

    }, [])


    const handleLetsGo = async () => {
        try {
            await relationShipValidation.validate(data, { abortEarly: false });
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const updatedUserData = {
                ...currentUserData,
                onBoardingThree: true,
                data: {
                    ...currentUserData.data,
                    relationshipStatus: data.relationshipStatus,
                    martialStatus: data.martialStatus,
                },
            };
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
            navigate('/on-boarding-4');
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    const handleBackBtn = () => {
        const serializedObject = localStorage.getItem('userData');
        const currentUserData = JSON.parse(serializedObject) || {};
        const updatedUserData = {
            ...currentUserData,
            onBoardingTwo: false,
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        navigate('/on-boarding-2')
    }

    const handleDropdownChange = useCallback((value) => {
        setData(prev => ({ ...prev, relationshipStatus: value }))
    }, [])

    return (
        <div className="flex flex-col min-h-screen bg-white lg:flex-row">
            <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                    <h1 className="p-4 font-semibold text-7xl">Curated Events<br />and Experiences</h1>
                    <p className="p-4">Couple Squad is thrilled to host a variety of events for <br /> Malayalees. Our main goal is to introduce new mixers to <br /> Malayalees, enriching their social experiences.</p>
                </div>
            </div>


            <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen mx-auto my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">

                <img src={back_btn_icon} alt="back" onClick={handleBackBtn} className="absolute top-0 left-0 p-2 mt-5 ml-5 text-sm text-white cursor-pointer" />

                <div className="w-[90%] md:w-7/12 mx-auto mb-5 lg:mb-0">
                    <h1 className="text-2xl font-semibold text-black font-body">Relationship Status</h1>

                    <CustomDropdown onChange={handleDropdownChange} option={data.relationshipStatus} />    {/* Drop Down Component */}

                    {data.relationshipStatus &&
                        <div className="flex flex-col w-full mt-10 space-y-3 md:w-10/12">
                            {(data.relationshipStatus === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions).map((label, index) => (
                                <label
                                    key={index}
                                    onClick={() => setData(prevData => ({ ...prevData, martialStatus: label.value, }))}
                                    className={`p-3 w-full text-center text-black border border-gray-300 rounded-md cursor-pointer 
                                ${data.martialStatus === label.value ? 'text-brandRed border border-red-400' : 'bg-white text-black border border-gray-300'}`}
                                >
                                    {label.label}
                                </label>
                            ))}
                        </div>
                    }


                    <button className="w-full p-3 mt-10 text-sm text-white border md:w-10/12 bg-brandRed" onClick={handleLetsGo}>
                        Let's Go
                    </button>
                </div>

                <div className="absolute inset-x-0 bottom-0 lg:bottom-10">
                    <div className="w-full bg-gray-200">
                        <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "42%" }}></div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Index;

