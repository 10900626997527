import AccountBlocked from '../accountBlocked'
import BlockAndReportUser from '../blockAndReportUser'
import EventBookingSuccess from '../success/eventBookingSuccess'
import PaymentSuccess from '../success/paymentSuccess'
import ToVerifyAccount from '../toVerifyAccount'

// Alert Popups
import PlanUpgrade from '../PlanUpgrade'
import NodataAccess from '../NodataAccess'
import LocationTurnoff from '../LocationTurnoff'
import InsufficientCode from '../insufficientCoins'
import LimitReached from '../LimitReached'
import NetworkData from '../NetworkData'
import NoMessage from '../NoMessage'
import SomethingWentWrong from '../somethingWentWrong'

// Assets
import avatar from 'assets/avatar.png'

function Index() {

    const blockAndReportUserData = {
        name: "Demo User",
        photo: avatar
    }

    return (
        <div className='flex flex-wrap gap-2 w-fit'>
            <AccountBlocked />
            <BlockAndReportUser data={blockAndReportUserData} type={'block'} />
            <ToVerifyAccount />
            <PaymentSuccess />
            <EventBookingSuccess paymentTime={'2024-12-07T10:53:16.701Z'} eventName={'Demo'} senderName={'Demo User'} booking_id={'1234567890'} />

            {/* Alert Popups */}
            <PlanUpgrade />
            <NodataAccess />
            <LocationTurnoff />
            <InsufficientCode />
            <LimitReached />
            <NetworkData />
            <NoMessage />
            <SomethingWentWrong />
        </div>
    )
}

export default Index