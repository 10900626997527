
import React from 'react';
import { MainFooter, MainFooterResponsive } from 'components/Footer';
import { SiteIntroNavbar } from "components/NavbarAndTabs";
import { avoidOptions } from 'utils/constant/routeHandler';


function Index({ children, avoid = [] }) {

    const isAvoiding = (option) => avoid?.includes(option); // Utility to check if a component should be avoided

    return (
        <div className="flex flex-col min-h-screen">
            {/* Conditionally render Navbar */}
            {!isAvoiding(avoidOptions.SITE_INTRO_NAV) && (
                <div className="fixed top-0 z-50 w-full">
                    <SiteIntroNavbar />
                </div>

            )}

            {/* Page Content */}
            <div className="flex-grow">
                {children}
            </div>

            {/* Conditionally render Footer */}
            {!isAvoiding(avoidOptions.FOOTER) && (
                <>
                    <div className="hidden w-full bg-black lg:block">
                        <MainFooter />
                    </div>
                    <div className="block bg-black lg:hidden">
                        <MainFooterResponsive />
                    </div>
                </>
            )}
        </div>
    );
}

export default Index;
